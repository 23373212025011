.featured-categories {
    position: relative;
    background-color: $off_white;
    color: $dark_blue;
    text-align: center;
    padding: 40px 10px;

    .card-icon {
        height: 80px;
        width: auto;
    }

}

.featured-categories .svg-stroke {
    stroke: $dark_blue;
}

.featured-categories .svg-fill {
    fill: $dark_blue;
}

.featured-categories img {
    width: 80%;
    height:auto;
}

.feat-cat-headline {
    max-width: 600px;
    display: block;
}

.category-grid {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    max-width: 300px;
    margin: 40px auto;

    @include for-tablet-portrait-up {
        max-width: 600px;
    }

    @include for-desktop-up {
        max-width: 1200px;
    }
}

.topic-card {
    position: relative;
    background-color: $white;
    width: 260px;
    height: 275px;
    margin: 10px;
    box-shadow: 0 0 5px 5px rgba(0,0,0,.05);

    &:hover{
        cursor: pointer;
        transition: transform .2s linear;
        transform: scale(1.05);

        .svg-fill {
            transition: fill .2s ease;
            fill: $light_blue;
        }

        .svg-stroke {
            transition: stroke .2s ease;
            stroke: $light_blue;
        }
    }
}

.topic-card-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;

    .cat-title {
        font-size: 1.15em;
        font-weight: 600;
        letter-spacing: .15em;
        line-height: 1.5em;
        text-transform: uppercase;
        padding: 20px 0;
    }
    a {
        text-decoration: underline;
        font-style: italic;
        color: $red;
    }
}

.btnTopics {
    margin-top: 1em;
    margin-bottom: 3em;
}

.featured-categories .popflag {
    position: absolute;
    display: inline-block;
    top: 15px;
    left: -5px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.flag {
    height: 30px;
    width: 125px;
}

.blue-flag-1 {
    fill: $light_blue;
}

.blue-flag-2 {
    fill: $blue;
}

.popular-text {
    position: absolute;
    top: 4px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    text-transform: uppercase;
    color: $white;
    font-weight: 400;
    letter-spacing: .15em;
}
