.review-list-body {
  background-color: $off_white;
  padding-top: 1em;
  padding-bottom: 1em;

  p,
  h2 {
    color: $blue;
  }
}

.ban-text {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1em;
  color: #e5e5e5;
  text-align: center;
  width: 100%;
  height: 100%;
}
.ban-text h3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $white;
  margin: 0;
  padding: 0;
}

.img-container {
  position: relative;
  margin: 0 auto 1.5em;
  width: 200px;
}

.img-container img {
  display: block;
}

.hoverlogo:hover {
  transition: opacity 0.2s linear;
  opacity: 0.7;
  cursor: pointer;
}

.review-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $white;
  text-align: center;
  margin: 1em 1.5em 2em;
  padding: 1em 1em;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);
  @include for-tablet-landscape-up {
    display: none;
  }

  h3 {
    display: inline;
    color: $black;
    font-size: 0.9em;
    font-weight: 700;
    letter-spacing: 0.1em;
  }
  .rating-area {
    margin: 1em auto;
  }
  .num-rating {
    color: $red;
  }

  .button {
    display: block;
    margin: 2em auto;
    text-decoration: none;
  }

  p {
    margin: 1em auto;
  }
}

.red {
  stroke: $red;
  fill: $red;
}

.expanded-card-content {
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.2s linear;
  width: 100%;
  .card-header {
    display: grid;
    grid-template-columns: 1fr 1px 1fr;
    grid-template-rows: 2em auto 1px;
    margin-bottom: 2em;
  }

  .num-rating {
    font-size: 3em;
    font-weight: 300;
    letter-spacing: 0.03em;
    align-self: center;
  }

  h3 {
    color: $dark_grey;
    font-weight: 400;
  }

  .review-label {
    margin: 1.5em auto;
    display: block;
  }

  .bullet-list {
    text-align: left;
    max-width: 400px;
    margin: 1em auto;
  }

  .bullet-list p {
    font-weight: 400;
  }
}

.card-horizontal-line {
  background-color: $grey;
  width: 100%;
  height: 1px;
  margin-top: 1em;
}

.expanded-card-logo {
  width: auto;
  max-width: 90%;
  height: auto;
  margin: 0 auto;
  align-self: center;
}

.downarrow {
  position: relative;
  width: 35px;
  height: auto;
}

.collapsible-content {
  max-height: 0;
  overflow: hidden;
}

.arrow-rotate {
  transform: rotate(180deg);
}

.card-logo {
  position: relative;
  display: block;
  top: 1em;
  margin: 0 auto;
  max-width: 200px;
}

.read-full a {
  font-style: italic;
  color: $red;
}

.h-line {
  height: 1px;
  background-color: $grey;
  margin: 2em auto;
}

.offer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  justify-self: center;
  align-self: center;
  flex: 1;
  width: 100%;
}

.offer-headline {
  font-weight: 400;
  width: 100%;
}

.offer-subtext {
  font-style: italic;
  width: 100%;
}

//Desktop

.review-card-DT {
  position: relative;
  display: none;
  background-color: $white;
  margin: 1em auto 2em;
  max-width: 900px;
  padding: 2em;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);

  h3 {
    color: $dark_grey;
    font-size: 0.9em;
    font-weight: 400;
  }

  .bullet-list {
    text-align: left;
  }
  .bullet-list p {
    font-weight: 400;
  }

  @include for-tablet-landscape-up {
    display: flex;
  }
}

.brand-box {
  display: flex;
  flex-direction: column;
  margin-right: 2em;
  align-items: center;
  justify-content: center;
  width: 25%;

  .num-rating {
    color: $red;
    font-size: 3em;
    font-weight: 300;
    letter-spacing: 0.03em;
    align-self: start;
  }
}

.thebrand {
  text-align: center;
}

.brand-box a {
  display: block;
  text-align: center;
  align-self: start;
  margin: 0 auto 1em;
  img {
    object-fit: contain;
    max-width: 200px;
    max-height: 100px;
  }
}

.card-brand {
  padding-bottom: 2em;
}

.review-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 0 2em;
  border-left: 1px solid $grey;
  border-right: 1px solid $grey;
  width: 45%;

  .card-headline {
    margin-top: 1em;
    width: 100%;
  }
}

.deal-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-left: 2em;
  width: 30%;

  h3 {
    text-align: center;
  }
  h2 {
    text-align: center;
    line-height: 1.25em;
  }
  p {
    text-align: center;
    margin-top: 1.5em;
    line-height: 1.4em;
  }
  .dealbutton {
    align-self: flex-end;
    margin-top: 0;
  }
}

.starbox {
  text-align: center;
  margin-top: 3em;
}

.stay-put {
  display: inline-block;
  width: 25px;
  text-align: left;
}

.editor-ba {
  position: absolute;
  display: inline-block;
  top: 15px;
  left: -7px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 150px;
  visibility: hidden;
}

.editor-flag {
  height: 38px;
  width: 150px;
}

.red-flag-1 {
  fill: #ff2f47;
}

.red-flag-2 {
  fill: #710511;
}

.editor-text {
  position: absolute;
  top: 8px;
  left: 0px;
  font-size: 14px;
  text-transform: uppercase;
  color: $white;
  font-weight: 400;
  letter-spacing: 0.15em;
  width: 100%;
  text-align: center;
}

.bullets-card {
  width: 100%;
}

.card-vert-line {
  background-color: $grey;
  width: 1px;
  flex: 0;
}

.card-content {
  width: 100%;
}

.top-disclaimer {
  background-color: $off_white;
  text-align: center;
  padding: 2em 1em 0px;
  p {
    max-width: 900px;
    font-weight: bold;
  }
  a,
  a:active {
    color: inherit;
  }
}

.bottom-disclaimer {
  background-color: $white;
  text-align: center;
  padding: 2em;
  p {
    max-width: 900px;
    font-weight: bold;
  }
  a,
  a:active {
    color: inherit;
  }
}
