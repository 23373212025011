.signup {
    background-color: $blue;
    color: $white;
    text-align: center;
    min-height: 220px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $gray;
        font-style: italic;
        font-weight: 200;
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $gray;
        font-style: italic;
        font-weight: 200;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        color: $gray;
        font-style: italic;
        font-weight: 200;
    }
}

.signup h2 {
    padding: 1.5em;
    font-weight: 200;
    color: $white;
}

.signup p {
    font-weight: 200;
    font-style: italic;
    font-size: .8em;
    letter-spacing: .1em;
    padding-bottom: 2em;
    color: $white;
}

.signup input[type=email] {
    background-color: rgba(0,0,0,0);
    border-width: 0px;
    color: $white;
    font-weight: 200;
    width: 100%;
    outline: none;
    caret-color: $grey;
    margin: 0;
    padding: 0;
    text-indent: 5px;
    text-align: left;
    &::-ms-clear {
        display: none;
    }
}

.arrow-animate {
    animation: arrow-bounce 1s infinite;
}

@keyframes arrow-bounce {
  0%   { right: 0px; }
  50%   { right: -10px; }
  100%   { right: 0px; }
}

.success-message {
    display:none;
    font-style: italic;
    padding: 0 2em;
    h2 {
        color: $red;
    }
    p {
        color: $white;
    }
}

.error-message {
    display:block;
    color: $red;
    text-align: center;
    margin: 0 auto 1em;
}

.signup-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .signup-line {
        width: 100%;
        height: 2px;
        background-color: $dark_grey;
        padding: 0;
        margin: 4px auto 0;
        display: block;
    }

    .flashybutton {
        margin-top: 30px;
    }

    @include for-tablet-landscape-up {
        max-width: 700px;
        margin: 0 auto;
        
        .input-wrapper {
            position: relative;
        }

        .flashybutton {
            display: none;
        }

        .signup-line {
            margin-bottom: 30px;
        }

    }
}

.bouncy-submit {
    display: none;
    position: absolute;
    color: $red;
    top: 0;
    right: 0px;

    .arrow-icon {
        width:15px;
        height: 10px;
        transform: rotate(180deg);
        position: absolute;
        top: 5px;
        right: 0;

        &:hover {
            cursor: pointer;
        }
    }

    .small-submit {
        color: $red;
        background-color: rgba(0,0,0,0);
        border: none;
        font-weight: 200;
        font-size: .9em;
        text-transform: uppercase;
        letter-spacing: .1em;
        padding: 0 25px 0 0;
        &:hover {
            cursor: pointer;
        }
    }
    
    @include for-tablet-landscape-up {
        display: inline-block;
    }
}

.input-wrapper {
    width: 100%;
    max-width: 700px;
}