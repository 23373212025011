.policy-wrapper {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px 20px 0 20px;
    h2{
        font-size: 40px;
        text-align: center;
        margin-bottom: 40px;
    }
    h3{
        margin-bottom: 10px;
        letter-spacing: .025em;
    }
    p{
        margin-bottom: 30px;
    }
}