hr {
  color: $grey;
  background-color: $grey;
}

.horizontal-line {
  padding: 20px 0;
}

.ba-holder,
.sidebar-holder {
  text-align: center;
  margin: 30px 0;
}

.tc-mobile {
  @include for-tablet-landscape-up {
    display: none;
  }
}

.tc-desktop {
  display: none;
  flex-direction: row;
  margin: 20px auto;

  .a,
  .b {
    flex: 1;
    margin: 10px;
  }

  @include for-tablet-landscape-up {
    display: flex;
  }
}
.tc-mobile {
  margin: 30px auto;
}
.tc-mobile,
.tc-desktop {
  p img {
    object-fit: cover;
    width: 100%;
  }
}

.image-caption {
  display: block;
  color: #999;
  font-style: italic;
  padding: 10px 0 0;
}

.full-width-image {
  margin: 2em 0;
}
.full-width-image img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}
.img-dt {
  display: none;
  @include for-tablet-portrait-up {
    display: block;
  }
}
.img-mb {
  @include for-tablet-portrait-up {
    display: none;
  }
}

.bullet-list {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 1em 0;
  max-width: 100%;

  p {
    margin: 0 0 0 15px;
    padding: 0 0 0 1em;
    text-align: left;
  }
}

.bullet-item {
  position: relative;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.bullet-check::before {
  display: inline-block;
  content: "";
  height: 23px;
  width: 23px;
  position: absolute;
  top: 2px;
  background: url("/site/themes/reviewtheme/img/Check-icon-Blue.svg");
}

.bullet-wand::before {
  display: inline-block;
  content: "";
  height: 23px;
  width: 23px;
  position: absolute;
  top: 2px;
  background: url("/site/themes/reviewtheme/img/Star-icon-Blue.svg");
}

.bullet-row {
  display: flex;
}

.bullet {
  width: 23px;
  height: 23px;
  padding-top: 2px;
}

.html-block {
  display: block;
}

.big-quote {
  margin: 40px auto;
  text-align: center;
}

.big-quote .quote-text {
  color: $light_blue;
  font-style: italic;
  font-size: 26px;
  line-height: 38px;
  margin: 0 auto 20px;
  padding: 0 20px;
  max-width: 700px;
}

.big-quote .quote-author {
  text-transform: uppercase;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: 0.05em;
}

.ba-holder.ba-300-250 {
  min-height: 250px;
  min-width: 300px;
  padding: 0;
  @include for-tablet-landscape-up {
    display: none;
  }
}

.ba-holder.ba-300-600 {
  min-height: 600px;
  min-width: 300px;
  padding: 0;
  margin: 0;
}

.ba-holder.ba-728-90 {
  min-height: 90px;
  min-width: 728px;
  padding: 0;
  display: none;
  @include for-tablet-landscape-up {
    display: block;
  }
}

.ba-holder.ba-160-600 {
  min-height: 600px;
  min-width: 160px;
  padding: 0;
  margin: 0;
}

.sidebar-holder.ba-300-600 {
  min-height: 600px;
  min-width: 300px;
  padding: 0;
  margin: 0;
}

.to-mobile {
  max-width: 300px;
  margin: 30px auto;
  text-align: center;

  p {
    text-align: center;
  }

  @include for-tablet-landscape-up {
    display: none;
  }
}

.offer h3 {
  color: #9a9a9a;
  font-size: 0.9em;
  font-weight: 400;
  text-transform: uppercase;
}

.textoff .offer h3 {
  margin-top: 24px;
}

.h-op:hover {
  transition: opacity 0.2s linear;
  opacity: 0.8;
}

.criteria-block {
  margin: 1.2em 0;
}
.criterion {
  color: $light_blue;
  font-weight: 400;
  letter-spacing: 0.17em;
  text-transform: uppercase;
}
.below-wrap .criterion-content p:first-child {
  margin-top: 0.5em;
}
