.homepage-hero {
    display: flex;
    flex-direction: column;
    color: $white;

    .hero-image {
        flex: 0 0 auto;
    }

    a {
        text-decoration: none;
    }

    img {
        display: block;
        width: 100%;
    }

    .hero-image-container {
        width: 100%;
    }

    @include for-tablet-landscape-up {
        flex-direction: row-reverse;
    }

    .flashybutton {
        margin: 2em auto 0;
    }
    
}

.homepage-hero .headline {
    background-color: $blue;
    width: 100%;

    h1 {
        width: 100%;
    }

    @include for-tablet-landscape-up {
        flex: 1;
    }

}

.homepage-hero .hero-image-container {
    display: inline-block;
    height: auto;
    overflow: hidden;
    @include for-tablet-landscape-up {
        flex:1;
    }
}

.homepage-hero .content-wrap {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 40px 30px;
    box-sizing: border-box;
    width: 100%;
    text-align: center;

    @include for-tablet-landscape-up {
        align-items: flex-start;
        text-align: left;
        padding: 20px 40px;
    }

    @include for-desktop-up {
        padding: 40px 60px;
    }

    @include for-big-desktop-up {
        padding: 40px 80px;
        h1 {
            font-size: 2.5em;
        }
        .flashybutton {
            margin: 3em auto 0;
        }
    }

}

  