.review-wrapper {
  display: flex;
  padding: 1em 1.5em;
  color: $blue;
  max-width: 1200px;
  margin: 0 auto;

  h2 {
    text-align: left;
    margin: 1em 0 0.5em;
  }

  p {
    text-align: left;
    margin: 1em 0;
  }
}

.ad-banners {
  max-width: 300px;
  display: none;

  @include for-desktop-up {
    display: block;
    margin-left: 15px;
  }
}

.review-hero {
  background-color: $blue;
  text-align: center;
  padding: 3em 20px;
  color: $white;
  font-weight: 300;

  h1 {
    margin-bottom: 0.5em;
    text-transform: capitalize;
  }

  h3 {
    color: $light_blue;
    margin: 0 auto 1em;
    font-size: 0.8em;
    font-weight: 700;
  }

  p {
    max-width: 768px;
    letter-spacing: 0.05em;
    color: $white;
  }
}

.rating-section {
  width: 100%;
  background-color: $off_white;
  text-align: center;
  padding-bottom: 2em;
  color: $blue;
  h2 {
    padding: 1.5em 0 0.3em;
  }
  h4 {
    color: $red;
    font-size: 2em;
  }
  h3 {
    color: $red;
    font-size: 0.8em;
    font-weight: 700;
    letter-spacing: 0.2em;
    margin: 0.5em auto;
  }
  .rating-guide {
    margin-bottom: 1.5em;
  }
}
.rating-container {
  padding: 0 2em;
  max-width: 1200px;
  margin: 0 auto;
  h2 {
    text-transform: capitalize;
  }
}

.rating-grid {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;

  p {
    font-size: 0.9em;
  }
  @include for-tablet-landscape-up {
    flex-direction: row;
  }
}

.rating {
  padding: 15px;
  flex: 1 0 auto;

  @include for-tablet-landscape-up {
    flex: 1;
  }
}

div.rating-grid div:nth-child(1) {
  border-bottom: 1px solid $grey;

  @include for-tablet-landscape-up {
    border-bottom: none;
    border-right: 1px solid $grey;
  }
}

div.rating-grid div:nth-child(2) {
  border-bottom: 1px solid $grey;

  @include for-tablet-landscape-up {
    border-bottom: none;
    border-right: 1px solid $grey;
  }
}

div.rating-grid div:nth-child(3) {
  border-bottom: 1px solid $grey;

  @include for-tablet-landscape-up {
    border-bottom: none;
    border-right: 1px solid $grey;
  }
}

.testimonials {
  margin: 0 auto;
  max-width: 900px;
  padding-bottom: 2em;
}

.testimonials h2 {
  margin: 1em auto 0.5em;
  text-align: center;
}

.carousel {
  background-color: $white;
  margin: 1.2em auto 1.5em;
}

.flickity-slider {
  display: flex;
  align-items: center;
}

.testimonial-card {
  color: $light_blue;
  text-align: center;
  margin: 1em auto;
  height: 100%;
  p {
    font-weight: 400;
    padding: 0 2em 1em;
    font-size: 1.1em;
    color: $light_blue;
  }
  .author {
    display: inline-block;
    padding: 0 2em;
    text-align: center;
    color: $dark_blue;
    font-style: italic;
    text-decoration: none;
    font-weight: 400;
  }
}

.carousel-cell {
  background-color: $white;
  width: 80%;
  margin-right: 125px;
  counter-increment: carousel-cell;
}

// For IE
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .carousel-cell {
    top: 0;
  }
}

.banner-mb {
  padding-top: 1em;
}

.textoff .offer {
  .offer-headline,
  .offer-subtext {
    text-align: center;
  }
}

.textoff {
  padding: 0 10px;
}

#review-body {
  flex: 1;
  @include for-desktop-up {
    padding-right: 20px;
  }
}
