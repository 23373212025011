.nav {
  position: relative;
}
.disclaimers {
  display: none;
  @include for-tablet-landscape-up {
    display: block;
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    font-weight: 700;
    color: $dark_blue;
    a {
      margin: 0px 15px;
      text-decoration: none;
      color: inherit;
    }
    a:active {
      color: inherit;
    }
    .red {
      color: $red;
    }
  }
}
.d-link {
  padding: 10px 0px;
}

#burger {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  @include for-tablet-landscape-up {
    display: none;
  }
}

.burger {
  background-color: $black;
  height: 4px;
  border-radius: 2px;
  display: inline-block;
}

.outer-burger {
  width: 30px;
  justify-self: end;
}

.inner-burger {
  width: 20px;
  justify-self: end;
}

.navbar {
  padding: 0.5em 1em;
  margin: 0 auto;
}

.dropdown-wrapper {
  padding: 2em 1.5em;

  @include for-tablet-landscape-up {
    display: none;
  }
}

.nav-dropdown {
  position: absolute;
  width: 100%;
  top: 100%;
  background-color: $dark_blue;
  transition: all 0.2s ease-in-out;
  max-height: 0px;
  overflow: hidden;
  z-index: 100;

  h2 {
    padding: 1.5em 0;
    color: $white;
    font-size: 1.1em;
  }

  .oc-title,
  a {
    display: block;
    color: $white;
    font-size: 2em;
    text-decoration: none;
  }

  .oc-title:hover,
  a:hover {
    color: $red;
  }
}

.dropdown-wrapper-DT {
  display: none;
  grid-template-columns: 1fr 1fr;
  padding: 4em 8em 2em;

  @include for-tablet-landscape-up {
    display: grid;
  }
}

.nav-blurb {
  font-style: italic;
  color: $light_blue;
  margin-bottom: 2em;
}

.nav-links-DT {
  a {
    margin-bottom: 1em;
  }
}

.our-cats {
  margin-bottom: 2em;
  a {
    margin: 0;
  }
}

.about-link {
  display: block;
  margin-bottom: 1em;
}

#drop-cats-dt,
#drop-cats-mb {
  max-height: 0px;
  overflow: hidden;
  a {
    font-size: 1.2em;
    padding: 1em;
  }
}

.svg-logo {
  width: 175px;
  height: 42px;
  @include for-tablet-landscape-up {
    width: 200px;
    height: 49px;
  }
}

.dropcat-title {
  display: flex;
  height: 2em;
  align-items: center;
  cursor: pointer;
  &:hover {
    .caret {
      fill: $red;
    }
    .oc-title {
      color: $red;
    }
  }
}
.caret {
  height: 25px;
  width: auto;
  fill: $white;
  align-self: center;
  justify-self: left;
  padding-left: 10px;
  padding-top: 5px;
}
.flip-caret {
  transition: all 0.2s ease;
  transform: rotateX(180deg);
}
.carety {
  display: inline-block;
  width: 30px;
}
